import styled from 'styled-components';
import { Box } from 'rebass';
import ArrowDownSVG from './image/arrow-down.svg';
import BrazilSVG from './image/Brazil.svg';
import ArgentinaSVG from './image/Argentina.svg'
import ChileSVG from './image/Chile.svg'
import ColombiaSVG from './image/Colombia.svg'
import DominicanRepublicSVG from './image/Dominican-Republic.svg'
import EcuadorSVG from './image/Ecuador.svg'
import GuatemalaSVG from './image/Guatemala.svg'
import MexicoSVG from './image/Mexico.svg'
import PeruSVG from './image/Peru.svg'
import ParaguaySVG from './image/Paraguay.svg'
import ElSalvadorSVG from './image/El-Salvador.svg'
import HondurasSVG from './image/Honduras.svg'
import PanamaSVG from './image/Panama.svg'
import UruguaiSVG from './image/Uruguai.svg'
import CostaRicaSVG from './image/Costa-Rica.svg'
import MarrocosSVG from './image/Marrocos.svg';

export const ArrowDown = styled(Box)`
  width: 32px;
  height: 32px;
  background-image: url(${ArrowDownSVG});
`;

const BaseIconCountry = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 24px;
  height: 24px;
`;

export const Brazil = styled(BaseIconCountry)`
  background-image: url(${BrazilSVG});
`;

export const Argentina = styled(BaseIconCountry)`
  background-image: url(${ArgentinaSVG});
`;

export const Chile = styled(BaseIconCountry)`
  background-image: url(${ChileSVG});
`;

export const Colombia = styled(BaseIconCountry)`
  background-image: url(${ColombiaSVG});
`;

export const DominicanRepublic = styled(BaseIconCountry)`
  background-image: url(${DominicanRepublicSVG});
`;

export const Ecuador = styled(BaseIconCountry)`
  background-image: url(${EcuadorSVG});
`;

export const Guatemala = styled(BaseIconCountry)`
  background-image: url(${GuatemalaSVG});
`;

export const Mexico = styled(BaseIconCountry)`
  background-image: url(${MexicoSVG});
`;

export const Peru = styled(BaseIconCountry)`
  background-image: url(${PeruSVG});
`;

export const Paraguay = styled(BaseIconCountry)`
  background-image: url(${ParaguaySVG});
`;

export const ElSalvador = styled(BaseIconCountry)`
  background-image: url(${ElSalvadorSVG});
`;

export const Honduras = styled(BaseIconCountry)`
  background-image: url(${HondurasSVG});
`;

export const Panama = styled(BaseIconCountry)`
  background-image: url(${PanamaSVG});
`;

export const Uruguai = styled(BaseIconCountry)`
  background-image: url(${UruguaiSVG});
`;

export const CostaRica = styled(BaseIconCountry)`
  background-image: url(${CostaRicaSVG});
`;

export const Marrocos = styled(BaseIconCountry)`
  background-image: url(${MarrocosSVG});
`;

export default {
  ArrowDown,
  Brazil,
  Argentina,
  Chile,
  Colombia,
  DominicanRepublic,
  Ecuador,
  Guatemala,
  Mexico,
  Peru,
  Paraguay,
  ElSalvador,
  Honduras,
  Panama,
  Uruguai,
  CostaRica,
  Marrocos,
};
