import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import {
  Brazil as BrazilIcon,
  Argentina as ArgentinaIcon,
  Chile as ChileIcon,
  Colombia as ColombiaIcon,
  DominicanRepublic as DominicanRepublicIcon,
  Ecuador as EcuadorIcon,
  Guatemala as GuatemalaIcon,
  Mexico as MexicoIcon,
  Peru as PeruIcon,
  Paraguay as ParaguayIcon,
  ElSalvador as ElSalvadorIcon,
  Honduras as HondurasIcon,
  Panama as PanamaIcon,
  Uruguai as UruguaiIcon,
  CostaRica as CostaRicaIcon,
  Marrocos,
} from './image-country';

const ItemCountryStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 8px;
  font-size: ${(props) => props.theme.fontSizes[1]};

  .label-country {
    margin-left: 8px;
  }
`;

function ItemCountry(props) {
  const context = useContext(Context);
  const {
    i18n,
  } = context;
  const {
    onClick,
    locale,
  } = props;

  const renderCountry = () => {
    const country = {
      'pt-BR': <BrazilIcon />,
      'es-AR': <ArgentinaIcon />,
      'es-CL': <ChileIcon />,
      'es-CO': <ColombiaIcon />,
      'es-DO': <DominicanRepublicIcon />,
      'es-EC': <EcuadorIcon />,
      'es-GT': <GuatemalaIcon />,
      'es-MX': <MexicoIcon />,
      'es-PE': <PeruIcon />,
      'es-PY': <ParaguayIcon />,
      'es-SV': <ElSalvadorIcon />,
      'es-HN': <HondurasIcon />,
      'es-PA': <PanamaIcon />,
      'es-UY': <UruguaiIcon />,
      'es-CR': <CostaRicaIcon />,
      'fr-MA': <Marrocos />,
    }

    return country[locale] || '';
  };

  return (
    <ItemCountryStyled onClick={onClick}>
      {renderCountry()}
      <p className="label-country">{i18n.t(locale)}</p>
    </ItemCountryStyled>
  );
}

ItemCountry.defaultProps = {
  locale: '',
  onClick: () => {},
};

ItemCountry.propTypes = {
  locale: PropTypes.string,
  onClick: PropTypes.func,
};

export default ItemCountry;
